import React, { Component } from 'react'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MenuMain } from './style';
import Cookies from 'js-cookie';
import { configuration } from '../../helper/appconfig';
import axios from 'axios';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { connect } from 'react-redux';
import { handleAuthTokenError } from '../../redux/actions/authActions';
import { handleLogout } from "../../redux/actions/authActions";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

class AccountMenu extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl : null,
      loading : false,
      logoutLoading : false,
      secretCode : ""
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl : event.currentTarget
    })
  };

  handleClose = () => {
    this.setState({
      anchorEl : null
    })
  };

  deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    console.log(cookies,"AllCookies")
  }

  enableMFA = () => {
    const { authData, handleAuthTokenError } = this.props;
    this.setState({ loading : true })
    const url = configuration.mfaSetup;
    const tokenType = authData?.tokenType;
    const idToken = authData?.idToken;
    console.log(url);
    const payload = {
      "access_token" : authData?.accessToken || Cookies?.get("accessToken"), 
    }
    const headers = {
      "Authorization" : `${tokenType} ${idToken}`
    }
    console.log(payload,"payload")
    axios.post(url,payload,{headers})
    .then((res) => {
      console.log(res.data,"responseFromSetupMFA");
      toastAlert(res.data?.message ? res.data?.message : "Secret key is generated", "success")
      this.handleClose();
      this.setState({
        loading : false,
        secretCode : res.data.secret_code,
      })
      this.props.history.push(`/MFASetupVerification/${this.state.secretCode}`)
    })
    .catch((err) => {
      this.handleClose();
      console.error(err,"enableMFAError")
      let errorMessage = err?.response?.data?.message;
      if(errorMessage === "Unauthorized") {
        handleAuthTokenError(this.props)
      }
      this.setState({
        loading : false,
      })
      toastAlert(errorMessage ? errorMessage : "Something went wrong. Please try again later.","error")
    });
  }

  disableMFA = () => {
    const { authData, handleAuthTokenError } = this.props;
    this.setState({ loading : true })
    const url = configuration.disableMFA;
    const tokenType = authData?.tokenType;
    const idToken = authData?.idToken;
    console.log(url);
    const payload = {
      "access_token" : authData?.accessToken || Cookies?.get("accessToken")
    }
    const headers = {
      "Authorization" : `${tokenType} ${idToken}`
    }
    console.log(payload,"payload")
    axios.put(url,payload,{ headers })
    .then((res) => {
      console.log(res.data);
      this.setState({ loading : false })
      this.handleClose();
      toastAlert(res.data?.message ? res.data?.message : "MFA disabled successfully","success")
			Cookies.set('MFAEnabled',false)
    })
    .catch((err) => {
      console.error(err,"disableMFAError")
      let errorMessage = err?.response?.data?.message;
      if(errorMessage === "Unauthorized") {
        handleAuthTokenError(this.props)
      }
      this.setState({ loading : false })
      this.handleClose()
      toastAlert(errorMessage ? errorMessage : "Something went wrong. Please try again later.","error")
    });
  }

  render() {
    const { anchorEl, loading, logoutLoading } = this.state
    const { authData } = this.props
    return (
      <MenuMain>
        <React.Fragment>
            <IconButton
              onClick={this.handleClick}
            >
              <Avatar >{(authData?.userEmail || Cookies.get('email'))?.charAt(0).toUpperCase()}</Avatar>
            </IconButton>
            <Menu
              className="account_dropdown"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              // onClick={this.handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible !important',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                className="iconavtar"
                style={{textAlign:"center"}}
              >
                <div>
                  <Avatar 
                    style={{
                      margin:"0 auto",
                      width:"60px",
                      height:"60px",
                      background:"var(--light-button)"}}
                    />
                  <Typography
                    style={{marginTop:"15px"}}
                    variant="h6"
                    className="admin-title"
                  >
                    {(authData?.firstName || Cookies.get('firstName')) + " " + (authData?.lastName || Cookies.get('lastName'))}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="small-text"
                  >
                    {authData?.userEmail || Cookies.get('email')}
                  </Typography>
                </div>
              </MenuItem>
              <Divider />
              {/* <Link to="/userProfile">
                <MenuItem
                  onClick={()=> this.handleClose()}
                  className="account-menu-dropdown"
                >
                  <ListItemIcon>
                    <AccountBoxIcon
                      style={{color:"#FFF"}}
                    />
                  </ListItemIcon>
                  Profile
                </MenuItem>
              </Link> */}
              <MenuItem 
                onClick={()=> this.props.handleLogout(this)}
                className="account-menu-dropdown"
              >
                <ListItemIcon>
                  <LogoutIcon
                    style={{color:"#FFF"}}
                  />
                </ListItemIcon>
                  Logout
                  <>
                    { logoutLoading &&
                      <CircularProgress 
                        style={{ 
                          color: "white",
                          display:"flex",
                          justifyContent:"center",
                          marginLeft:"10px"
                        }} 
                        size={25}
                      />
                    }
                  </>
              </MenuItem>
              <MenuItem 
                onClick={()=> Cookies.get("MFAEnabled") === "true" ? this.disableMFA() : this.enableMFA()} 
                className="account-menu-dropdown"

              >
                <ListItemIcon>
                { Cookies.get("MFAEnabled") === "true" ? 
                  <ToggleOnIcon
                    style={{color:"#a5dc86"}}
                  />
                  :
                  <ToggleOffIcon
                    style={{color:"#FFF"}}
                  />
                }
                </ListItemIcon>
                  { Cookies.get("MFAEnabled") === "true" ? 
                    "Disable MFA" : 
                    "Enable MFA"
                  }
                  <>
                    { loading &&
                      <CircularProgress 
                        style={{ 
                          color: "white",
                          display:"flex",
                          justifyContent:"center",
                          marginLeft:"10px"
                        }} 
                        size={25}
                      />
                    }
                  </>   
              </MenuItem>
               {Cookies.get("userRole") === "TenantAdmin" && Cookies.get("isTenantLogin") === "true" && <Link to="/support">
                <MenuItem
                  onClick={()=> this.handleClose()}
                  className="account-menu-dropdown"
                >
                  <ListItemIcon>
                    <SupportAgentIcon
                      style={{color:"#FFF"}}
                    />
                  </ListItemIcon>
                  Support
                </MenuItem>
              </Link> }
            </Menu>
        </React.Fragment>
      </MenuMain>
    )
  }
}


const mapStateToProps = (state) => ({
	authData : state.authReducer,
})

export default withRouter(connect(mapStateToProps, { handleLogout, handleAuthTokenError })(AccountMenu))
import React, { Component } from 'react'
import { CircularProgress, Grid } from '@mui/material';
export default function asynComponent(importComponent){
   
    
     class AsyncComponent extends Component {
        constructor (props){
            super(props);

            this.state ={
                component : null
            }
        }
        async componentDidMount() {
            const { default : component } = await importComponent();

            this.setState({
                component : component
            });
        }
        render() {
            const C =this.state.component;
        return C ? <C {...this.props} /> : <Grid container style={{display:"flex",justifyContent:"center"}}>
        <CircularProgress 
        style={{ color: "var(--secondary-dark)",display:"flex",alignItem:"center",justifyContent:"center",height:"100vh"}}
        size={50}
    />
</Grid>; 
          
      }
    }
    return AsyncComponent;
}